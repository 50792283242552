import * as React from 'react';
import Stack from '@mui/material/Stack';

import { useListHomeSpotsQuery, ListHomeSpotsQuery } from '@graphql';
import { showAppLoaderVar } from '@context';
import { Sliders, Highlight } from '@components';

const SpotsPage = () => {
  const [homeSpots, setHomeSpots] = React.useState<ListHomeSpotsQuery['homeSpots'] | null>(null);

  const { loading } = useListHomeSpotsQuery({
    onCompleted: ({ homeSpots }) => {
      setHomeSpots(homeSpots ?? null);
    },
  });

  if (loading) {
    showAppLoaderVar(true);
  } else {
    showAppLoaderVar(false);
  }

  if (!homeSpots) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="spot" />
      <Sliders
        categories={homeSpots.reduce((categories, category) => {
          if (category.spots.length) {
            categories.push(category);
          }

          return categories;
        }, [] as ListHomeSpotsQuery['homeSpots'])}
      />
    </Stack>
  );
};

export default SpotsPage;
